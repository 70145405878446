@import '../styles/mixin';

.app {
  background: #fafafa;
  display: flex;
  justify-content: center;

  .content {
    @include scale(max-width, 720px);
    @include scale(box-shadow, 0 0 15px 0 rgba(0, 0, 0, 0.08));
    overflow: hidden;

    position: absolute;

    width: 100%;
    height: 100%;
    background-color: #fff;
    box-sizing: border-box;
    padding: 40px;

    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: 100%;
      max-width: 420px;
      object-fit: contain;
    }
  }
}
